import "./App.css";
import { commingSoon } from "./assets";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
// import Layout from "./components/Common/Layout";

function App() {
  
  return (
    <div className="relative">
      <img
        className="h-[100vh] w-[100vw] object-cover"
        src={commingSoon}
        alt="logo"
        height={30}
        width={100}
      />
      <div className="absolute top-20 left-7 lg:top-[10%] lg:left-[40%] flex flex-row gap-2 ">
        <a
          href="https://www.facebook.com/sandeep.bashyal.75"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookSquare className="h-20 w-20 p-2 bg-transparent shadow-sm" />
        </a>
        <a
          href="https://www.instagram.com/i_am_sandeep.b/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram className="h-20 w-20 p-2  bg-transparent shadow-sm" />
        </a>
        <a
          href="https://www.linkedin.com/in/pitamber-bashyal-351529213/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin className="h-20 w-20 p-2 bg-transparent shadow-sm" />
        </a>
      </div>
    </div>
  );
}

export default App;
